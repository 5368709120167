<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 15H8C6.34315 15 5 16.3431 5 18V18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M8 4H19C19.5523 4 20 4.44772 20 5V20C20 20.5523 19.5523 21 19 21H8C6.34315 21 5 19.6569 5 18V7C5 5.34315 6.34315 4 8 4Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M9 8L16 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M9 11H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Book',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
