import AlarmCircle from "../components/components/icons/AlarmCircle.vue";
import ArchiveData from "../components/components/icons/ArchiveData.vue";
import ArrowDown from "../components/components/icons/ArrowDown.vue";
import ArrowLeft from "../components/components/icons/ArrowLeft.vue";
import ArrowRight from "../components/components/icons/ArrowRight.vue";
import ArrowUp from "../components/components/icons/ArrowUp.vue";
import Award from "../components/components/icons/Award.vue";
import BarChart from "../components/components/icons/BarChart.vue";
import BarChartDown from "../components/components/icons/BarChartDown.vue";
import BarChartUp from "../components/components/icons/BarChartUp.vue";
import BatteryFull from "../components/components/icons/BatteryFull.vue";
import BatteryLow from "../components/components/icons/BatteryLow.vue";
import BatteryMedium from "../components/components/icons/BatteryMedium.vue";
import BatteryMediumHigh from "../components/components/icons/BatteryMediumHigh.vue";
import BatteryMediumLow from "../components/components/icons/BatteryMediumLow.vue";
import BatteryNull from "../components/components/icons/BatteryNull.vue";
import Bell from "../components/components/icons/Bell.vue";
import BellImportant from "../components/components/icons/BellImportant.vue";
import BellNotification from "../components/components/icons/BellNotification.vue";
import BellNotificationOff from "../components/components/icons/BellNotificationOff.vue";
import BellNotificationOn from "../components/components/icons/BellNotificationOn.vue";
import Biotech from "../components/components/icons/Biotech.vue";
import Book from "../components/components/icons/Book.vue";
import Bookmark from "../components/components/icons/Bookmark.vue";
import Burgermenu from "../components/components/icons/Burgermenu.vue";
import BurgermenuOpen from "../components/components/icons/BurgermenuOpen.vue";
import CalendarCheckmark from "../components/components/icons/CalendarCheckmark.vue";
import CalendarDefault from "../components/components/icons/CalendarDefault.vue";
import CalendarMinus from "../components/components/icons/CalendarMinus.vue";
import CalendarNotification from "../components/components/icons/CalendarNotification.vue";
import CalendarPlus from "../components/components/icons/CalendarPlus.vue";
import Calibration from "../components/components/icons/Calibration.vue";
import Card from "../components/components/icons/Card.vue";
import Cases from "../components/components/icons/Cases.vue";
import Catalogue from "../components/components/icons/Catalogue.vue";
import Celcius from "../components/components/icons/Celcius.vue";
import Chat1 from "../components/components/icons/Chat1.vue";
import Chat2 from "../components/components/icons/Chat2.vue";
import ChatCheck from "../components/components/icons/ChatCheck.vue";
import ChatError from "../components/components/icons/ChatError.vue";
import ChatNotification from "../components/components/icons/ChatNotification.vue";
import ChatQuestion from "../components/components/icons/ChatQuestion.vue";
import ChatSend from "../components/components/icons/ChatSend.vue";
import ChatWriting from "../components/components/icons/ChatWriting.vue";
import Checkmark from "../components/components/icons/Checkmark.vue";
import CheckmarkCircle from "../components/components/icons/CheckmarkCircle.vue";
import CheckmarkRectangle from "../components/components/icons/CheckmarkRectangle.vue";
import ChevronDown from "../components/components/icons/ChevronDown.vue";
import ChevronLeft from "../components/components/icons/ChevronLeft.vue";
import ChevronRight from "../components/components/icons/ChevronRight.vue";
import ChevronUp from "../components/components/icons/ChevronUp.vue";
import ClockCircle from "../components/components/icons/ClockCircle.vue";
import ClockRectangle from "../components/components/icons/ClockRectangle.vue";
import Cloud from "../components/components/icons/Cloud.vue";
import CloudCheck from "../components/components/icons/CloudCheck.vue";
import CloudDown from "../components/components/icons/CloudDown.vue";
import CloudError from "../components/components/icons/CloudError.vue";
import CloudNoConnection from "../components/components/icons/CloudNoConnection.vue";
import CloudUp from "../components/components/icons/CloudUp.vue";
import Compas from "../components/components/icons/Compas.vue";
import Cross from "../components/components/icons/Cross.vue";
import CrossCircle from "../components/components/icons/CrossCircle.vue";
import CrossRectangle from "../components/components/icons/CrossRectangle.vue";
import DataLogger from "../components/components/icons/DataLogger.vue";
import DataLoggerWifi from "../components/components/icons/DataLoggerWifi.vue";
import DataLoggerWifiNoConnection from "../components/components/icons/DataLoggerWifiNoConnection.vue";
import Delivery from "../components/components/icons/Delivery.vue";
import DeviationCircle from "../components/components/icons/DeviationCircle.vue";
import DeviationRectangle from "../components/components/icons/DeviationRectangle.vue";
import DeviationTriangle from "../components/components/icons/DeviationTriangle.vue";
import DeviceDesktop from "../components/components/icons/DeviceDesktop.vue";
import DeviceMobile from "../components/components/icons/DeviceMobile.vue";
import Dna from "../components/components/icons/Dna.vue";
import Document1 from "../components/components/icons/Document1.vue";
import Document2 from "../components/components/icons/Document2.vue";
import DocumentDown from "../components/components/icons/DocumentDown.vue";
import DocumentError from "../components/components/icons/DocumentError.vue";
import DocumentMinus from "../components/components/icons/DocumentMinus.vue";
import DocumentPlus from "../components/components/icons/DocumentPlus.vue";
import DocumentUp from "../components/components/icons/DocumentUp.vue";
import Dollar from "../components/components/icons/Dollar.vue";
import DollarCoin from "../components/components/icons/DollarCoin.vue";
import DownloadDefault from "../components/components/icons/DownloadDefault.vue";
import DownloadHover from "../components/components/icons/DownloadHover.vue";
import Edit from "../components/components/icons/Edit.vue";
import Email from "../components/components/icons/Email.vue";
import EmailCheck from "../components/components/icons/EmailCheck.vue";
import EmailNotification from "../components/components/icons/EmailNotification.vue";
import EuprySquid from "../components/components/icons/EuprySquid.vue";
import Export from "../components/components/icons/Export.vue";
import Eye from "../components/components/icons/Eye.vue";
import EyeOff from "../components/components/icons/EyeOff.vue";
import Facebook from "../components/components/icons/Facebook.vue";
import Farenheite from "../components/components/icons/Farenheite.vue";
import Folder from "../components/components/icons/Folder.vue";
import FolderCheck from "../components/components/icons/FolderCheck.vue";
import FolderDelete from "../components/components/icons/FolderDelete.vue";
import FolderDown from "../components/components/icons/FolderDown.vue";
import FolderMinus from "../components/components/icons/FolderMinus.vue";
import FolderOpen from "../components/components/icons/FolderOpen.vue";
import FolderPlus from "../components/components/icons/FolderPlus.vue";
import FolderUp from "../components/components/icons/FolderUp.vue";
import Food from "../components/components/icons/Food.vue";
import Fridge from "../components/components/icons/Fridge.vue";
import Globe1 from "../components/components/icons/Globe1.vue";
import Globe2 from "../components/components/icons/Globe2.vue";
import GraphDown from "../components/components/icons/GraphDown.vue";
import GraphUp from "../components/components/icons/GraphUp.vue";
import Headset from "../components/components/icons/Headset.vue";
import Heart from "../components/components/icons/Heart.vue";
import Home1 from "../components/components/icons/Home1.vue";
import Home2 from "../components/components/icons/Home2.vue";
import Hourglass from "../components/components/icons/Hourglass.vue";
import Humidity from "../components/components/icons/Humidity.vue";
import HumidityDown from "../components/components/icons/HumidityDown.vue";
import HumidityUp from "../components/components/icons/HumidityUp.vue";
import Incubator from "../components/components/icons/Incubator.vue";
import InfoCircle from "../components/components/icons/InfoCircle.vue";
import InfoRectangle from "../components/components/icons/InfoRectangle.vue";
import Instagram from "../components/components/icons/Instagram.vue";
import Joystick from "../components/components/icons/Joystick.vue";
import Laboratory from "../components/components/icons/Laboratory.vue";
import LinkedIn from "../components/components/icons/LinkedIn.vue";
import Login from "../components/components/icons/Login.vue";
import Logistics from "../components/components/icons/Logistics.vue";
import Logout from "../components/components/icons/Logout.vue";
import MapPin from "../components/components/icons/MapPin.vue";
import MapPinCheck from "../components/components/icons/MapPinCheck.vue";
import Mapping from "../components/components/icons/Mapping.vue";
import MapPinMinus from "../components/components/icons/MapPinMinus.vue";
import MapPinPlus from "../components/components/icons/MapPinPlus.vue";
import Minus from "../components/components/icons/Minus.vue";
import MinusCircle from "../components/components/icons/MinusCircle.vue";
import MinusRectangle from "../components/components/icons/MinusRectangle.vue";
import Monitoring from "../components/components/icons/Monitoring.vue";
import MoreHorizontal from "../components/components/icons/MoreHorizontal.vue";
import MoreVertical from "../components/components/icons/MoreVertical.vue";
import Move from "../components/components/icons/Move.vue";
import MoveData from "../components/components/icons/MoveData.vue";
import MultipleUser from "../components/components/icons/MultipleUser.vue";
import Percent from "../components/components/icons/Percent.vue";
import PercentCircle from "../components/components/icons/PercentCircle.vue";
import PercentPolygon from "../components/components/icons/PercentPolygon.vue";
import PercentRectangle from "../components/components/icons/PercentRectangle.vue";
import Pharma from "../components/components/icons/Pharma.vue";
import Pharmacies from "../components/components/icons/Pharmacies.vue";
import Phone from "../components/components/icons/Phone.vue";
import PhoneCall from "../components/components/icons/PhoneCall.vue";
import PhoneIncoming from "../components/components/icons/PhoneIncoming.vue";
import PhoneMissed from "../components/components/icons/PhoneMissed.vue";
import PhoneOff from "../components/components/icons/PhoneOff.vue";
import PhoneOutgoing from "../components/components/icons/PhoneOutgoing.vue";
import PieChart1 from "../components/components/icons/PieChart1.vue";
import PieChart2 from "../components/components/icons/PieChart2.vue";
import PieChart3 from "../components/components/icons/PieChart3.vue";
import Play from "../components/components/icons/Play.vue";
import PlayCircle from "../components/components/icons/PlayCircle.vue";
import PlayRectangle from "../components/components/icons/PlayRectangle.vue";
import Plus from "../components/components/icons/Plus.vue";
import PlusCircle from "../components/components/icons/PlusCircle.vue";
import PlusRectangle from "../components/components/icons/PlusRectangle.vue";
import QrCode from "../components/components/icons/QrCode.vue";
import QuestionCircle from "../components/components/icons/QuestionCircle.vue";
import QuestionRectangle from "../components/components/icons/QuestionRectangle.vue";
import RoundLockLocked from "../components/components/icons/RoundLockLocked.vue";
import RoundLockOpen from "../components/components/icons/RoundLockOpen.vue";
import Search from "../components/components/icons/Search.vue";
import Send from "../components/components/icons/Send.vue";
import Sensor from "../components/components/icons/Sensor.vue";
import SensorNoConnection from "../components/components/icons/SensorNoConnection.vue";
import Settings from "../components/components/icons/Settings.vue";
import Shield from "../components/components/icons/Shield.vue";
import ShieldCheckmark from "../components/components/icons/ShieldCheckmark.vue";
import ShieldError from "../components/components/icons/ShieldError.vue";
import ShieldNotification from "../components/components/icons/ShieldNotification.vue";
import ShippingBox from "../components/components/icons/ShippingBox.vue";
import SlidersHorizontal from "../components/components/icons/SlidersHorizontal.vue";
import SlidersVertical from "../components/components/icons/SlidersVertical.vue";
import Smiley from "../components/components/icons/Smiley.vue";
import Snowflake from "../components/components/icons/Snowflake.vue";
import SnowflakeSimple from "../components/components/icons/SnowflakeSimple.vue";
import SquareLockLocked from "../components/components/icons/SquareLockLocked.vue";
import SquareLockOpen from "../components/components/icons/SquareLockOpen.vue";
import Star from "../components/components/icons/Star.vue";
import StarRectangle from "../components/components/icons/StarRectangle.vue";
import Testimonial from "../components/components/icons/Testimonial.vue";
import Therometer from "../components/components/icons/Therometer.vue";
import TherometerDown from "../components/components/icons/TherometerDown.vue";
import TherometerUp from "../components/components/icons/TherometerUp.vue";
import TimerCircle from "../components/components/icons/TimerCircle.vue";
import Train from "../components/components/icons/Train.vue";
import Transport from "../components/components/icons/Transport.vue";
import Trash from "../components/components/icons/Trash.vue";
import TrashClear from "../components/components/icons/TrashClear.vue";
import TrashNotification from "../components/components/icons/TrashNotification.vue";
import User1 from "../components/components/icons/User1.vue";
import User2 from "../components/components/icons/User2.vue";
import User3 from "../components/components/icons/User3.vue";
import UserCircle from "../components/components/icons/UserCircle.vue";
import UserSquare from "../components/components/icons/UserSquare.vue";
import Wallet from "../components/components/icons/Wallet.vue";
import Wifi from "../components/components/icons/Wifi.vue";
import WifiFair from "../components/components/icons/WifiFair.vue";
import WifiNoConnection from "../components/components/icons/WifiNoConnection.vue";
import WifiWeak from "../components/components/icons/WifiWeak.vue";
import Youtube from "../components/components/icons/Youtube.vue";


const iconImports = {
    AlarmCircle,
    ArchiveData,
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    Award,
    BarChart,
    BarChartDown,
    BarChartUp,
    BatteryFull,
    BatteryLow,
    BatteryMedium,
    BatteryMediumHigh,
    BatteryMediumLow,
    BatteryNull,
    Bell,
    BellImportant,
    BellNotification,
    BellNotificationOff,
    BellNotificationOn,
    Biotech,
    Book,
    Bookmark,
    Burgermenu,
    BurgermenuOpen,
    CalendarCheckmark,
    CalendarDefault,
    CalendarMinus,
    CalendarNotification,
    CalendarPlus,
    Calibration,
    Card,
    Cases,
    Catalogue,
    Celcius,
    Chat1,
    Chat2,
    ChatCheck,
    ChatError,
    ChatNotification,
    ChatQuestion,
    ChatSend,
    ChatWriting,
    Checkmark,
    CheckmarkCircle,
    CheckmarkRectangle,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ClockCircle,
    ClockRectangle,
    Cloud,
    CloudCheck,
    CloudDown,
    CloudError,
    CloudNoConnection,
    CloudUp,
    Compas,
    Cross,
    CrossCircle,
    CrossRectangle,
    DataLogger,
    DataLoggerWifi,
    DataLoggerWifiNoConnection,
    Delivery,
    DeviationCircle,
    DeviationRectangle,
    DeviationTriangle,
    DeviceDesktop,
    DeviceMobile,
    Dna,
    Document1,
    Document2,
    DocumentDown,
    DocumentError,
    DocumentMinus,
    DocumentPlus,
    DocumentUp,
    Dollar,
    DollarCoin,
    DownloadDefault,
    DownloadHover,
    Edit,
    Email,
    EmailCheck,
    EmailNotification,
    EuprySquid,
    Export,
    Eye,
    EyeOff,
    Facebook,
    Farenheite,
    Folder,
    FolderCheck,
    FolderDelete,
    FolderDown,
    FolderMinus,
    FolderOpen,
    FolderPlus,
    FolderUp,
    Food,
    Fridge,
    Globe1,
    Globe2,
    GraphDown,
    GraphUp,
    Headset,
    Heart,
    Home1,
    Home2,
    Hourglass,
    Humidity,
    HumidityDown,
    HumidityUp,
    Incubator,
    InfoCircle,
    InfoRectangle,
    Instagram,
    Joystick,
    Laboratory,
    LinkedIn,
    Login,
    Logistics,
    Logout,
    MapPin,
    MapPinCheck,
    Mapping,
    MapPinMinus,
    MapPinPlus,
    Minus,
    MinusCircle,
    MinusRectangle,
    Monitoring,
    MoreHorizontal,
    MoreVertical,
    Move,
    MoveData,
    MultipleUser,
    Percent,
    PercentCircle,
    PercentPolygon,
    PercentRectangle,
    Pharma,
    Pharmacies,
    Phone,
    PhoneCall,
    PhoneIncoming,
    PhoneMissed,
    PhoneOff,
    PhoneOutgoing,
    PieChart1,
    PieChart2,
    PieChart3,
    Play,
    PlayCircle,
    PlayRectangle,
    Plus,
    PlusCircle,
    PlusRectangle,
    QrCode,
    QuestionCircle,
    QuestionRectangle,
    RoundLockLocked,
    RoundLockOpen,
    Search,
    Send,
    Sensor,
    SensorNoConnection,
    Settings,
    Shield,
    ShieldCheckmark,
    ShieldError,
    ShieldNotification,
    ShippingBox,
    SlidersHorizontal,
    SlidersVertical,
    Smiley,
    Snowflake,
    SnowflakeSimple,
    SquareLockLocked,
    SquareLockOpen,
    Star,
    StarRectangle,
    Testimonial,
    Therometer,
    TherometerDown,
    TherometerUp,
    TimerCircle,
    Train,
    Transport,
    Trash,
    TrashClear,
    TrashNotification,
    User1,
    User2,
    User3,
    UserCircle,
    UserSquare,
    Wallet,
    Wifi,
    WifiFair,
    WifiNoConnection,
    WifiWeak,
    Youtube,
};

export default iconImports;
