<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 21C2.34315 21 1 19.6569 1 18V6C1 4.34315 2.34315 3 4 3H6.67963C7.34834 3 7.9728 3.3342 8.34373 3.8906L9.40627 5.4844C9.7772 6.0408 10.4017 6.375 11.0704 6.375H16C17.6569 6.375 19 7.71815 19 9.375V11" stroke="currentColor" stroke-width="1.5"/>
<path d="M6.79687 13.0089L4 21H15.9137C17.4877 21 18.9154 20.0769 19.5613 18.6415L22.3653 12.4104C22.6631 11.7486 22.1791 11 21.4534 11H9.62844C8.35361 11 7.21801 11.8057 6.79687 13.0089Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'FolderOpen',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
