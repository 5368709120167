<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4142 17.5858C14.1953 18.3668 14.1953 19.6332 13.4142 20.4142C12.6332 21.1953 11.3668 21.1953 10.5858 20.4142C9.80474 19.6332 9.80474 18.3668 10.5858 17.5858C11.3668 16.8047 12.6332 16.8047 13.4142 17.5858Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M7 15C9.76142 12.3333 14.2386 12.3333 17 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'WifiWeak',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
