<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1432 4.42382C11.5319 3.778 12.4682 3.778 12.8568 4.42382L14.744 7.55966C14.8836 7.79167 15.1114 7.95714 15.3752 8.01824L18.9407 8.84403C19.675 9.01409 19.9644 9.90456 19.4703 10.4738L17.0711 13.2376C16.8935 13.4421 16.8066 13.7098 16.83 13.9796L17.1464 17.6258C17.2116 18.3767 16.4541 18.9271 15.7601 18.6331L12.3901 17.2054C12.1408 17.0997 11.8593 17.0997 11.6099 17.2054L8.23996 18.6331C7.54592 18.9271 6.78845 18.3767 6.85362 17.6258L7.17006 13.9796C7.19347 13.7098 7.10648 13.4421 6.92897 13.2376L4.52978 10.4738C4.03567 9.90456 4.325 9.01409 5.05931 8.84403L8.62485 8.01824C8.88865 7.95714 9.1164 7.79167 9.25603 7.55966L11.1432 4.42382Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Star',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
