<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 10V8C21 6.34315 19.6569 5 18 5H6C4.34315 5 3 6.34315 3 8V16C3 17.6569 4.34315 19 6 19H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M7 9L12 13L17 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M22 15L18.25 19L16 17.1818" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'EmailCheck',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
