<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 10V21C8 21.5523 8.44772 22 9 22H15C15.5523 22 16 21.5523 16 21V10C16 9.44772 15.5523 9 15 9H9C8.44772 9 8 9.44772 8 10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="12" cy="13" r="1" stroke="currentColor" stroke-width="2"/>
<path d="M7 7C9.76142 4.33333 14.2386 4.33333 17 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M5 5C8.86599 1 15.134 1 19 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'DataLoggerWifi',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
