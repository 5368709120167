<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5452 7.64285C11.9486 9.10062 11.4948 10.6214 10.5005 11.5588C10.312 11.7366 10.2512 12.0239 10.3993 12.2365C10.7146 12.6891 11.0732 13.1194 11.4752 13.5217C11.8767 13.9235 12.3059 14.2819 12.7574 14.5971C12.9706 14.7459 13.2588 14.6841 13.4363 14.4941C14.3721 13.4918 15.8974 13.033 17.3591 13.4382C19.3294 13.9843 20.4384 15.8932 19.836 17.7018C18.071 23.0017 11.2755 17.7486 9.26351 15.7351C7.20294 13.673 2.03053 6.91654 7.28475 5.16411C9.09201 4.5613 10.9995 5.67108 11.5452 7.64285Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Phone',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
