<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 18.0002L12.5 7.00024M12.5 7.00024L18 12.5002M12.5 7.00024L7 12.5002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'ArrowUp',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
