<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.38082 7.48571C9.34257 5.99142 11.0213 5 12.9327 5C15.8132 5 18.1679 7.25145 18.3329 10.0906C18.3963 10.0879 18.46 10.0866 18.524 10.0866C20.997 10.0866 23.0016 12.0912 23.0016 14.5641C23.0016 17.037 20.997 19.0417 18.524 19.0417H6.8754C3.63048 19.0417 1 16.4112 1 13.1663C1 9.92139 3.63048 7.29088 6.8754 7.29088C7.39507 7.29088 7.89978 7.35852 8.38082 7.48571Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M15 11L11.25 15L9 13.1818" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'CloudCheck',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
