<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.9021 3.5901 15.6665 4.59721 17.1199C4.70168 17.2707 4.7226 17.4653 4.64529 17.6317L3.42747 20.2519C3.23699 20.5853 3.47768 21 3.86159 21H12Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M10 10.2416C10 8.74719 11.1208 8 12.2416 8C13.3624 8 14.1096 8.74719 14.1096 9.86798C14.1096 12.435 12.2416 12.1578 12.2416 13.6039" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<circle cx="12.3177" cy="16.1039" r="0.895932" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'ChatQuestion',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
