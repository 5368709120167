<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1819 20V13.8636M20.3637 7.04541H13.1819C12.6296 7.04541 12.1819 7.49313 12.1819 8.04541V13.8636M12.1819 13.8636H19.6819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="6.72733" cy="7.72727" r="1.97727" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Farenheite',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
