<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 10V22H7V10H3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 10V22H14V16.5C14 15.3954 14.8954 14.5 16 14.5C17.1046 14.5 18 15.3954 18 16.5V22H22V14.4408C22 11.7422 19.3826 9.81793 16.8069 10.6228L14 11.5V10H10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="5" cy="5" r="2.25" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'LinkedIn',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
