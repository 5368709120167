<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.05662" y="-0.0924426" width="8.5" height="12.5" rx="1.25" transform="matrix(0.766044 0.642788 0.642788 -0.766044 5.3076 11.8822)" stroke="currentColor" stroke-width="1.5"/>
<rect x="0.704416" y="-0.0616284" width="1" height="6.31754" rx="0.5" transform="matrix(0.766044 0.642788 0.642788 -0.766044 4.41285 19.3305)" stroke="currentColor"/>
</svg>
</template>
<script>
export default {
  name: 'Sensor',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
