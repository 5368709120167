<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 13.5L13.5 13.5M13.5 13.5L11.0294 16M13.5 13.5L11.0294 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 10.5V6C4 4.34315 5.34315 3 7 3H9.75C10.3795 3 10.9723 3.29639 11.35 3.8L12.4 5.2C12.7777 5.70361 13.3705 6 14 6H19C20.6569 6 22 7.34315 22 9V18C22 19.6569 20.6569 21 19 21H7C5.34315 21 4 19.6569 4 18V16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'ArchiveData',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
