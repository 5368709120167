<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 16L12 6M12 6L17.5 11.5M12 6L6.5 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M6 16.5V18C6 18.5523 6.44772 19 7 19H17C17.5523 19 18 18.5523 18 18V16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Export',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
