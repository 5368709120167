<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 5C3 3.34315 3.34315 3 5 3H8C9.65685 3 10 3.34315 10 5V8C10 9.65685 9.65685 10 8 10H5C3.34315 10 3 9.65685 3 8V5Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M14 5C14 3.34315 14.3431 3 16 3H19C20.6569 3 21 3.34315 21 5V8C21 9.65685 20.6569 10 19 10H16C14.3431 10 14 9.65685 14 8V5Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M14 16C14 14.3431 14.3431 14 16 14H19C20.6569 14 21 14.3431 21 16V19C21 20.6569 20.6569 21 19 21H16C14.3431 21 14 20.6569 14 19V16Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M3 16C3 14.3431 3.34315 14 5 14H8C9.65685 14 10 14.3431 10 16V19C10 20.6569 9.65685 21 8 21H5C3.34315 21 3 20.6569 3 19V16Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Cases',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
