<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.188 10.9343C20.7045 11.5613 20.7045 12.4387 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36426 18 5.23205 14.7899 3.81196 13.0657C3.29551 12.4387 3.29551 11.5613 3.81196 10.9343C5.23205 9.21014 8.36426 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Eye',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
