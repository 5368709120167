<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.6875C12 6.6875 8 3.6875 2 5.6875C2 10.6875 2 16.1875 2 18.1875C8 16.1875 12 19.6875 12 19.6875M12 6.6875C12 6.6875 16 3.6875 22 5.6875C22 9.18749 22 16.1875 22 18.1875C16 16.1875 12 19.6875 12 19.6875M12 6.6875V19.6875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 8.68751C5 8.68751 7 8.1875 9 9.18749" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 11.8125C5 11.8125 7 11.3125 9 12.3125" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Catalogue',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
