<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 8V17L12 20M4 8L12 11M4 8L8 6.5M12 11V20M12 11L16 9.5M12 20L16 18.5M20 8V17L16 18.5M20 8L12 5L8 6.5M20 8L16 9.5M16 18.5V9.5M16 9.5L8 6.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'ShippingBox',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
