<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 5H7.94152C7.08066 5 6.32438 5.55348 6.14718 6.39591C5.85932 7.76445 5.5 10.103 5.5 13.25C5.5 16.7574 5.94634 19.2606 6.24263 20.5359C6.38509 21.1491 6.85053 21.618 7.45797 21.7833C8.54498 22.0789 10.3778 22.5 12 22.5C13.6222 22.5 15.455 22.0789 16.542 21.7833C17.1495 21.618 17.6149 21.1491 17.7574 20.5359C18.0537 19.2606 18.5 16.7574 18.5 13.25C18.5 10.103 18.1407 7.76445 17.8528 6.39591C17.6756 5.55348 16.9193 5 16.0585 5H15L15 2.5H9L9 5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'BatteryNull',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
