<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.53027C9.51472 3.53027 7.26472 4.53763 5.63604 6.16631C4.00736 7.79499 3 10.045 3 12.5303C3 15.0156 4.00736 17.2656 5.63604 18.8942C7.26472 20.5229 9.51472 21.5303 12 21.5303C14.4853 21.5303 16.7353 20.5229 18.364 18.8942C19.9926 17.2656 21 15.0156 21 12.5303C21 10.045 19.9926 7.79499 18.364 6.16631C16.7353 4.53763 14.4853 3.53027 12 3.53027Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 8.53027L12 12.5303" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12.5303L14 14.5303" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 5L6 2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M21 5L18 2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'AlarmCircle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
