<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4" stroke="currentColor" stroke-width="1.5"/>
<path d="M12 3.99986C12 3.44758 12.4491 2.99412 12.998 3.05536C13.8372 3.149 14.6609 3.3605 15.4442 3.68495C16.5361 4.13724 17.5282 4.80017 18.364 5.6359C19.1997 6.47163 19.8626 7.46378 20.3149 8.55571C20.6394 9.33899 20.8509 10.1626 20.9445 11.0019C21.0057 11.5508 20.5523 11.9999 20 11.9999L13 11.9999C12.4477 11.9999 12 11.5521 12 10.9999L12 3.99986Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'PieChart1',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
