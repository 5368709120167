<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99991 5H7.94143C7.08057 5 6.32428 5.55348 6.14709 6.39591C5.85922 7.76445 5.49991 10.103 5.49991 13.25C5.49991 16.7574 5.94624 19.2606 6.24254 20.5359C6.385 21.1491 6.85044 21.618 7.45788 21.7833C8.54489 22.0789 10.3777 22.5 11.9999 22.5C13.6222 22.5 15.4549 22.0789 16.5419 21.7833C17.1494 21.618 17.6148 21.1491 17.7573 20.5359C18.0536 19.2606 18.4999 16.7574 18.4999 13.25C18.4999 10.103 18.1406 7.76445 17.8527 6.39591C17.6755 5.55348 16.9193 5 16.0584 5H14.9999V2.5H8.99991V5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.99992 10H14.9999C15.5522 10 15.9999 10.447 15.9999 10.9993L15.9999 14.5C15.9999 15.9326 15.7434 17.8783 15.5978 18.867C15.5371 19.2794 15.3389 19.6628 14.972 19.8606C14.4408 20.1471 13.485 20.5 11.9999 20.5C10.4848 20.5 9.52059 20.1327 8.99605 19.8433C8.65033 19.6526 8.45833 19.2947 8.39399 18.9051C8.24723 18.0166 7.9999 16.2583 7.99991 14.5L7.99991 10.9994C7.99991 10.4471 8.44763 10 8.99992 10Z" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'BatteryMediumHigh',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
