<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7 9.7823C19.4333 10.7679 19.4333 13.2321 17.7 14.2177L9.9 18.6531C8.16666 19.6387 6 18.4067 6 16.4354L6 7.5646C6 5.59331 8.16667 4.36126 9.9 5.3469L17.7 9.7823Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Play',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
