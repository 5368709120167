<template>
  <div v-html="renderedMarkdown"></div>
</template>
<script>
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

// Extend the link_open rule to correctly handle and skip modifications for tel: and mailto: links
md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  const aIndex = tokens[idx].attrIndex('href');
  if (aIndex >= 0) {
    let url = tokens[idx].attrs[aIndex][1];
    // Skip modification for hash-only, tel:, and mailto: links
    if (url === "#" || url.startsWith("#") || url.startsWith("tel:") || url.startsWith("mailto:")) {
      // Do nothing, skip the modification
    } else {
      // Prepend a leading slash to relative URLs that do not start with http(s)://, /, tel:, or mailto:
      if (!url.startsWith("/") && !url.startsWith("http://") && !url.startsWith("https://")) {
        url = '/' + url; // Prepend the leading slash
      }

      // Check and append a trailing slash if necessary
      const hashOrQueryRegex = /([#?].*)$/;
      const hasHashOrQuery = hashOrQueryRegex.test(url);
      if (hasHashOrQuery) {
        const parts = url.split(hashOrQueryRegex);
        let baseUrl = parts[0];
        let hashOrQuerySegment = url.substring(baseUrl.length);
        if (!baseUrl.endsWith('/')) {
          baseUrl += '/';
        }
        url = baseUrl + hashOrQuerySegment;
      } else if (!url.match(/(\.[a-zA-Z0-9]+|\/)$/)) {
        url += '/'; // Append a trailing slash for URLs that do not end with a file extension, hash, or query parameter
      }
    }
    // Update the href attribute with the modified URL
    tokens[idx].attrs[aIndex][1] = url;
  }

  // Return the default renderer
  return self.renderToken(tokens, idx, options);
};

export default {
  props: [
    'markdown'
  ],
  computed: {
    renderedMarkdown() {
      return md.render(String(this.markdown));
    }
  }
}
</script>