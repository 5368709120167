<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3333 12.0001C17.5 13.0001 18.3269 13.3504 19.124 14.1151C19.6376 14.6079 20 15.3067 20 16.1334C20 17.1644 19.2041 18.0001 18.2222 18.0001H5.77778C4.79594 18.0001 4 17.1644 4 16.1334C4 15.3067 4.36239 14.6079 4.87601 14.1151C5.67308 13.3504 6.66667 12.438 6.66667 11.3334V9.6001C6.66667 7.05169 7.87369 4.90086 10.2877 4.22266" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M14 21C13.7968 21.3031 13.505 21.5547 13.154 21.7295C12.803 21.9044 12.4051 21.9965 12 21.9965C11.5949 21.9965 11.197 21.9044 10.846 21.7295C10.495 21.5547 10.2032 21.3031 10 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 6C19 7.65685 17.6569 9 16 9C14.3431 9 13 7.65685 13 6C13 4.34315 14.3431 3 16 3C17.6569 3 19 4.34315 19 6Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'BellNotification',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
