<template>
  <svg :class="classes" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path class="transition-transform duration-700 group-hover:translate-y-0.5" d="M8 0.5V11.5M8 11.5L2.5 6M8 11.5L13.5 6" stroke="currentColor" stroke-width="1.5"/>
    <rect x="2" y="14" width="12" height="1.5" fill="currentColor"/>
  </svg>
</template>
<script>
export default {
  name: 'DownloadDefault',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
