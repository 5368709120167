<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6786 14.9287L9.5 4L5.32139 14.9287C4.2015 17.8576 6.36425 21 9.5 21C12.6357 21 14.7985 17.8576 13.6786 14.9287Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 14.5L8.5 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5 13.5V3M16.5 3L19 5.47059M16.5 3L14 5.47059" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'HumidityUp',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
