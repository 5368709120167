<template>
  <component :is="name" :classes="classes"></component>
</template>

<script lang="ts">
import iconImports from "../../assets/js/iconImports.js";

export default {
  components: {
    ...iconImports,
  },

  props: {
    name: {
      type: String,
      default: 'ArrowRight',
    },
    classes: {
      type: String,
      default: '',
    },
  }
};
</script>