<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.25 13C20.25 14.6317 19.7661 16.2267 18.8596 17.5835C17.9531 18.9402 16.6646 19.9976 15.1571 20.622C13.6496 21.2464 11.9908 21.4098 10.3905 21.0915C8.79016 20.7732 7.32015 19.9874 6.16637 18.8336C5.01259 17.6798 4.22685 16.2098 3.90852 14.6095C3.59019 13.0092 3.75357 11.3504 4.37799 9.84286C5.00242 8.33537 6.05984 7.0469 7.41655 6.14038C8.77325 5.23385 10.3683 4.75 12 4.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M3 6.11768L5.11765 4.00003" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10 2L14 2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M18.2732 6.86642L12.5755 13.9048C12.2194 14.3447 11.5607 14.3793 11.1606 13.9791C10.7604 13.5789 10.795 12.9203 11.2348 12.5642L18.2732 6.86642Z" fill="#070A26" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'TimerCircle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
