<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 9V15L14.5 12L10 9Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 7.68513V16.3149C3 17.8435 4.14941 19.1277 5.6687 19.2965L6.47842 19.3865C10.1482 19.7942 13.8518 19.7942 17.5216 19.3865L18.3313 19.2965C19.8506 19.1277 21 17.8435 21 16.3149V7.68513C21 6.15648 19.8506 4.87229 18.3313 4.70348L17.5216 4.61351C13.8518 4.20576 10.1482 4.20576 6.47843 4.61351L5.66871 4.70348C4.14941 4.87229 3 6.15648 3 7.68513Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Youtube',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
