<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M15 10V10C15 8.89543 14.1046 8 13 8H10.7434C9.78055 8 9 8.78055 9 9.74342V9.74342C9 10.4938 9.48019 11.1601 10.1921 11.3974L13.8079 12.6026C14.5198 12.8399 15 13.5062 15 14.2566V14.2566C15 15.2194 14.2194 16 13.2566 16H11C9.89543 16 9 15.1046 9 14V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 18V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'DollarCoin',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
