<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 5H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M8 5L8.11111 5C9.03159 5 9.77778 4.25381 9.77778 3.33333C9.77778 3.14924 9.92702 3 10.1111 3L13.8889 3C14.073 3 14.2222 3.14924 14.2222 3.33333C14.2222 4.25381 14.9684 5 15.8889 5H16" stroke="currentColor" stroke-width="1.5"/>
<path d="M18 9L17.2292 18.2491C17.0997 19.804 15.7999 21 14.2396 21H9.7604C8.20013 21 6.90033 19.804 6.77076 18.2491L6 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17L10.75 9C10.75 8.58579 10.4142 8.25 10 8.25C9.58579 8.25 9.25 8.58579 9.25 9V17ZM13.25 17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17L14.75 9C14.75 8.58579 14.4142 8.25 14 8.25C13.5858 8.25 13.25 8.58579 13.25 9L13.25 17Z" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'Trash',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
