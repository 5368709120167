<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 12V19C7 20.1046 7.89543 21 9 21H15C16.1046 21 17 20.1046 17 19V12C17 10.8954 16.1046 10 15 10H9C7.89543 10 7 10.8954 7 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M7 5V8C7 9.10457 7.89543 10 9 10H15C16.1046 10 17 9.10457 17 8V5C17 3.89543 16.1046 3 15 3H9C7.89543 3 7 3.89543 7 5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M13 12H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M13 5H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Fridge',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
