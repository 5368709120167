<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 19C5 16.7909 6.79086 15 9 15H15C17.2091 15 19 16.7909 19 19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19Z" stroke="currentColor" stroke-width="1.5"/>
<circle cx="12" cy="7" r="4" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'User1',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
