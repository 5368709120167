<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 6H13M4 13V17C4 18.1046 4.89543 19 6 19V19M4 13V5C4 3.89543 4.89543 3 6 3H18C19.1046 3 20 3.89543 20 5V13M4 13H20M20 13V17C20 18.1046 19.1046 19 18 19V19M6 19V21M6 19H18M18 19V21M7 16H8M17 16H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Train',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
