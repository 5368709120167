<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.8953 3.61496C19.725 3.37788 20.4922 4.14504 20.2551 4.97483L16.2555 18.9735C15.8269 20.4736 13.8232 20.7553 12.9975 19.4317L10.2001 14.9469C9.87735 14.4295 9.44059 13.9927 8.92318 13.67L4.43843 10.8726C3.11475 10.0469 3.39652 8.04317 4.89657 7.61459L18.8953 3.61496Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.1925 13.6776L13.728 10.1421" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Send',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
