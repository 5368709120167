<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 5H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M8 5L8.11111 5C9.03159 5 9.77778 4.25381 9.77778 3.33333C9.77778 3.14924 9.92702 3 10.1111 3L13.8889 3C14.073 3 14.2222 3.14924 14.2222 3.33333C14.2222 4.25381 14.9684 5 15.8889 5H16" stroke="currentColor" stroke-width="1.5"/>
<path d="M18 9L17.2292 18.2491C17.0997 19.804 15.7999 21 14.2396 21H9.7604C8.20013 21 6.90033 19.804 6.77076 18.2491L6 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5303 10.4697C10.2374 10.1768 9.76256 10.1768 9.46967 10.4697C9.17678 10.7626 9.17678 11.2374 9.46967 11.5303L11.0607 13.1213L9.46967 14.7123C9.17678 15.0052 9.17678 15.4801 9.46967 15.773C9.76256 16.0659 10.2374 16.0659 10.5303 15.773L12.1213 14.182L13.7123 15.773C14.0052 16.0659 14.4801 16.0659 14.773 15.773C15.0659 15.4801 15.0659 15.0052 14.773 14.7123L13.182 13.1213L14.773 11.5304C15.0659 11.2375 15.0659 10.7626 14.773 10.4697C14.4801 10.1768 14.0052 10.1768 13.7123 10.4697L12.1213 12.0607L10.5303 10.4697Z" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'TrashClear',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
