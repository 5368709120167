<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M11.5716 7.71191C11.7659 7.389 12.2341 7.389 12.4284 7.71191L13.5366 9.55331C13.6064 9.66931 13.7203 9.75205 13.8522 9.78259L15.9459 10.2675C16.313 10.3525 16.4577 10.7978 16.2107 11.0824L14.8018 12.7053C14.7131 12.8076 14.6696 12.9414 14.6813 13.0763L14.8671 15.2174C14.8997 15.5929 14.5209 15.8681 14.1739 15.721L12.1951 14.8827C12.0704 14.8299 11.9296 14.8299 11.805 14.8827L9.82609 15.721C9.47907 15.8681 9.10033 15.5929 9.13292 15.2174L9.31874 13.0763C9.33044 12.9414 9.28695 12.8076 9.19819 12.7053L7.78936 11.0824C7.54231 10.7978 7.68697 10.3525 8.05413 10.2675L10.1478 9.78259C10.2797 9.75205 10.3936 9.66931 10.4634 9.55331L11.5716 7.71191Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'StarRectangle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
