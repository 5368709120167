<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 12V7.5M12 2V7.5M12 7.5L17 4M12 7.5L7 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12L7.5 12M2 12H7.5M7.5 12L4 7M7.5 12L4 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12L12 16.5M12 22V16.5M12 16.5L7 20M12 16.5L17 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12L16.5 12M22 12L16.5 12M16.5 12L20 17M16.5 12L20 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'SnowflakeSimple',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
