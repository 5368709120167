<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.73606 21H19.264C20.7507 21 21.7177 19.4354 21.0528 18.1056L13.7888 3.57769C13.0518 2.1036 10.9482 2.1036 10.2111 3.5777L2.94721 18.1056C2.28231 19.4354 3.2493 21 4.73606 21Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M12.01 9V13M12.01 17H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'DeviationTriangle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
