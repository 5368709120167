<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.1389 4.25972C16.5998 4.07988 16.0328 4.00024 15.4649 4.00024C14 4.00023 12.9999 4.74047 12 5.50009C11.0176 4.75134 10 4.00024 8.55296 4.00024C7.99669 4.00024 7.41614 4.06017 6.88887 4.24093C3.53101 5.33339 2.33402 8.95705 3.35101 12.1244C3.92701 13.743 4.8549 15.2112 6.06088 16.4182C7.87741 18.1602 9.85555 19.6799 12 21.0001C14.1404 19.7148 16.1547 18.1553 17.9498 16.4271C19.1638 15.2201 20.0908 13.743 20.6578 12.1244C21.6577 8.95705 20.4607 5.33339 17.1389 4.25972Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Heart',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
