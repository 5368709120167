<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6786 14.9287L8.5 4L4.32139 14.9287C3.2015 17.8576 5.36425 21 8.5 21C11.6357 21 13.7985 17.8576 12.6786 14.9287Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 14.5L7.5 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5001 3V13.5M16.5001 13.5L14.0001 11.0294M16.5001 13.5L19.0001 11.0294" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'HumidityDown',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
