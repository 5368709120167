<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5855 8.17345L14.5854 8.17339L14.5786 8.18465C13.6284 9.74295 13.1814 11.6922 13.1814 13.9828V16C13.1814 17.5188 14.4126 18.75 15.9314 18.75H17.4421C18.9609 18.75 20.1921 17.5188 20.1921 16V14.8498C20.1921 13.331 18.9609 12.0998 17.4421 12.0998H17.0825C17.1594 11.0291 17.369 10.3015 17.6542 9.8533C17.9387 9.40632 18.4169 9.07516 19.2031 8.9162C19.7628 8.80303 20.2436 8.31544 20.2436 7.66696V6.58514C20.2436 5.83345 19.5569 5.19622 18.7529 5.37859C16.9249 5.79322 15.5094 6.71652 14.5855 8.17345ZM4.65418 8.17345L4.65408 8.17339L4.64721 8.18465C3.69702 9.74295 3.25 11.6922 3.25 13.9828V16C3.25 17.5188 4.48122 18.75 6 18.75H7.51075C9.02953 18.75 10.2607 17.5188 10.2607 16V14.8498C10.2607 13.331 9.02953 12.0998 7.51074 12.0998H7.15119C7.22803 11.0291 7.43765 10.3015 7.72288 9.8533C8.00732 9.40633 8.48554 9.07516 9.27173 8.9162C9.83142 8.80303 10.3122 8.31544 10.3122 7.66696V6.58514C10.3122 5.83345 9.62556 5.19622 8.82154 5.37859C6.99351 5.79322 5.57809 6.71652 4.65418 8.17345Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Testimonial',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
