<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 8.56244C6.75 4.8125 3 10.0624 3 14.1874C3 18.3125 7.5 21.3124 12 21.3124C16.5 21.3124 21 17.5625 21 13.8124C21 10.0624 17.25 4.8125 12 8.56244ZM12 8.56244C12 8.56244 9 4.4375 12 2.1875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.25 17.3125C15.5 16.9375 17.375 14.3125 17.75 13.1875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Food',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
