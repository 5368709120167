<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 12.5H5L7.5 6L9.5 19L13 9L15 15L16.5 12.5H18.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="20.5" cy="12.5" r="1.75" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Monitoring',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
