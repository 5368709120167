<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 5H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M8 5L8.11111 5C9.03159 5 9.77778 4.25381 9.77778 3.33333C9.77778 3.14924 9.92702 3 10.1111 3L13.8889 3C14.073 3 14.2222 3.14924 14.2222 3.33333C14.2222 4.25381 14.9684 5 15.8889 5H16" stroke="currentColor" stroke-width="1.5"/>
<path d="M6 9L6.77076 18.2491C6.90033 19.804 8.20013 21 9.7604 21H10.5M18 9L17.7591 11.8904" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M19 18C19 19.6569 17.6569 21 16 21C14.3431 21 13 19.6569 13 18C13 16.3431 14.3431 15 16 15C17.6569 15 19 16.3431 19 18Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M10 17L10 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M14 12L14 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'TrashNotification',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
