<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="6.72718" cy="7.72727" r="1.97727" stroke="currentColor" stroke-width="1.5"/>
<path d="M20.1462 14.8804C19.6777 16.057 18.8685 17.0103 17.8638 17.5992C16.8611 18.1869 15.7159 18.3822 14.6089 18.1623C13.5007 17.942 12.478 17.3146 11.7133 16.3627C10.9477 15.4097 10.4894 14.1907 10.4244 12.9009C10.3594 11.611 10.6923 10.3407 11.3603 9.29598C12.0276 8.25221 12.9854 7.50119 14.0681 7.14647C15.1489 6.79233 16.3055 6.84858 17.3564 7.30878C18.4093 7.76983 19.3056 8.61493 19.8875 9.72364" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Celcius',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
