<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 12V6.50865C19 5.61569 18.408 4.83092 17.5494 4.5856L12.5494 3.15703C12.1903 3.05443 11.8097 3.05443 11.4506 3.15703L6.45056 4.5856C5.59195 4.83092 5 5.61569 5 6.50865V11.8831C5 14.4667 6.24773 16.8913 8.35009 18.393L10 19.5715" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M19 18C19 19.6569 17.6569 21 16 21C14.3431 21 13 19.6569 13 18C13 16.3431 14.3431 15 16 15C17.6569 15 19 16.3431 19 18Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'ShieldNotification',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
