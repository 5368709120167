<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.704416" y="-0.0616284" width="1" height="6.31754" rx="0.5" transform="matrix(0.766044 0.642788 0.642788 -0.766044 4.41285 19.3305)" stroke="currentColor"/>
<path d="M3 3L21 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M17 13L19.8305 9.76515C20.1984 9.34464 20.1504 8.70419 19.7239 8.34326L14.7585 4.14184C14.3388 3.7867 13.7111 3.837 13.3533 4.25444L11 7M14.75 15.5L13.1488 17.2791C12.7873 17.6807 12.1721 17.7226 11.7596 17.3735L6.79103 13.1693C6.35878 12.8036 6.31614 12.1522 6.69703 11.7333L9.0899 9.10111" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'SensorNoConnection',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
