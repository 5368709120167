<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 15C6 11.6863 8.68629 9 12 9C15.3137 9 18 11.6863 18 15C18 18.3137 15.3137 21 12 21C8.68629 21 6 18.3137 6 15Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M15 10V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V10" stroke="currentColor" stroke-width="1.5"/>
<path d="M12 14V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'RoundLockLocked',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
