<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 18V13M9 18H8.5M9 18H15.5M9 13H2M9 13V9M2 13V18H3.5M2 13L4.4 9.8C4.77771 9.29639 5.37049 9 6 9H9M9 9V6C9 4.89543 9.89543 4 11 4H20C21.1046 4 22 4.89543 22 6V18H20.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="6" cy="18.5" r="2.25" stroke="currentColor" stroke-width="1.5"/>
<circle cx="18" cy="18.5" r="2.25" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Logistics',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
