<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.81196 13.0657L3.23305 13.5425L3.81196 13.0657ZM20.188 10.9343L19.6091 11.4111L20.188 10.9343ZM3.81196 10.9343L4.39088 11.4111H4.39088L3.81196 10.9343ZM20.188 13.0657L19.6091 12.5889V12.5889L20.188 13.0657ZM18.4659 13.8582C18.1751 14.1532 18.1786 14.628 18.4736 14.9188C18.7686 15.2095 19.2435 15.2061 19.5342 14.9111L18.4659 13.8582ZM10.8834 5.33859C10.4742 5.40303 10.1947 5.78697 10.2592 6.19614C10.3236 6.60531 10.7076 6.88477 11.1168 6.82032L10.8834 5.33859ZM12 17.25C10.4012 17.25 8.86214 16.5412 7.51312 15.5706C6.17002 14.6043 5.07855 13.4238 4.39087 12.5889L3.23305 13.5425C3.96546 14.4318 5.15014 15.7184 6.6371 16.7882C8.11814 17.8538 9.96308 18.75 12 18.75V17.25ZM12 6.75C13.5988 6.75 15.1378 7.45883 16.4869 8.4294C17.83 9.3957 18.9214 10.5762 19.6091 11.4111L20.7669 10.4575C20.0345 9.56824 18.8498 8.28159 17.3629 7.21178C15.8818 6.14624 14.0369 5.25 12 5.25V6.75ZM4.39087 12.5889C4.10254 12.2388 4.10254 11.7612 4.39088 11.4111L3.23305 10.4575C2.48848 11.3615 2.48848 12.6385 3.23305 13.5425L4.39087 12.5889ZM20.7669 13.5425C21.5115 12.6385 21.5115 11.3615 20.7669 10.4575L19.6091 11.4111C19.8974 11.7612 19.8974 12.2388 19.6091 12.5889L20.7669 13.5425ZM19.6091 12.5889C19.305 12.9581 18.9195 13.3979 18.4659 13.8582L19.5342 14.9111C20.0242 14.4139 20.4395 13.94 20.7669 13.5425L19.6091 12.5889ZM15.9318 15.9474C14.7182 16.7217 13.3807 17.25 12 17.25V18.75C13.7627 18.75 15.3804 18.0785 16.7386 17.2119L15.9318 15.9474ZM4.39088 11.4111C5.1224 10.523 6.3085 9.24667 7.76661 8.25172L6.92115 7.01269C5.29978 8.11904 4.01045 9.51361 3.23305 10.4575L4.39088 11.4111ZM11.1168 6.82032C11.4086 6.77436 11.7032 6.75 12 6.75V5.25C11.6209 5.25 11.2482 5.28113 10.8834 5.33859L11.1168 6.82032Z" fill="#070A26"/>
<path d="M3 3L21 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2236 14.8977C10.7149 14.7614 10.2511 14.4936 9.87869 14.1212C9.50632 13.7489 9.23853 13.285 9.10223 12.7764" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'EyeOff',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
