<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="16" height="16" rx="8" stroke="currentColor" stroke-width="1.5"/>
<circle cx="12" cy="12" r="3" stroke="currentColor" stroke-width="1.5"/>
<line x1="3.25" y1="11.75" x2="1.75" y2="11.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<line x1="12.25" y1="3.25" x2="12.25" y2="1.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<line x1="22.25" y1="11.75" x2="20.75" y2="11.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<line x1="12.25" y1="22.25" x2="12.25" y2="20.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Calibration',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
