<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="7" r="4" stroke="currentColor" stroke-width="1.5"/>
<path d="M15 14H9C6.23857 14 3.76508 16.5498 5.58248 18.6289C6.81838 20.0428 8.8622 21 12 21C15.1378 21 17.1816 20.0428 18.4175 18.6289C20.2349 16.5498 17.7614 14 15 14Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'User3',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
