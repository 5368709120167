<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01295 22C7.84929 18.8844 9.39207 16.9143 12.0007 15.9417C13.722 15.3001 16.0119 14.2954 16.0001 12.0062C15.9926 10.5557 15.0499 9.62093 13.925 8.96218M8.01295 2C7.88951 4.25556 8.66324 5.88641 10.0731 7.00011" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M15.9873 22C16.1059 19.742 15.3283 18.0857 13.8982 16.9747M15.9873 2C16.159 5.13656 14.5957 7.0651 11.9995 8.07483C10.2952 8.73767 8.01189 9.74543 8.00017 12.0062C7.99255 13.4759 8.86978 14.3454 10.0001 15" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M9.58003 21.0752H14.4202" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.79 5H13.2101" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M9.58003 2.92456H14.4202" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.8576 10.5713H13.1432M10.5001 12.9998H13.5001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.79 19H13.2101" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Dna',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
