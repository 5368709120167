<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Minus',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
