<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 14V22H14V14H16L18 10H14V8C14 6.89543 14.8954 6 16 6H17.5V2H15C11.6863 2 9 4.68629 9 8V10H6V14H9Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Facebook',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
