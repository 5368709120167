<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 9L9 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z" fill="#070A26"/>
<path d="M11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8C10.3284 8 11 8.67157 11 9.5Z" fill="#070A26"/>
<path d="M10.0011 3.7859C11.1396 2.76863 12.8604 2.76863 13.9989 3.7859L14.2406 4.00185C14.7473 4.4546 15.3927 4.72192 16.0711 4.76006L16.3947 4.77826C17.919 4.86396 19.1358 6.0808 19.2215 7.60513L19.2397 7.92872C19.2779 8.60715 19.5452 9.25253 19.998 9.75922L20.2139 10.0009C21.2312 11.1394 21.2312 12.8602 20.2139 13.9987L19.998 14.2404C19.5452 14.7471 19.2779 15.3924 19.2397 16.0709L19.2215 16.3945C19.1358 17.9188 17.919 19.1356 16.3947 19.2213L16.0711 19.2395C15.3927 19.2777 14.7473 19.545 14.2406 19.9977L13.9989 20.2137C12.8604 21.231 11.1396 21.231 10.0011 20.2137L9.75942 19.9977C9.25273 19.545 8.60735 19.2777 7.92893 19.2395L7.60533 19.2213C6.081 19.1356 4.86416 17.9188 4.77846 16.3945L4.76027 16.0709C4.72212 15.3925 4.4548 14.7471 4.00205 14.2404L3.7861 13.9987C2.76884 12.8602 2.76884 11.1394 3.7861 10.0009L4.00205 9.75922C4.4548 9.25253 4.72212 8.60715 4.76027 7.92872L4.77846 7.60513C4.86416 6.0808 6.081 4.86396 7.60533 4.77826L7.92892 4.76006C8.60735 4.72192 9.25273 4.4546 9.75942 4.00185L10.0011 3.7859Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'PercentPolygon',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
