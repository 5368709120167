<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 12.25L8.23536 8.603C8.07949 8.45201 7.94924 8.27665 7.84971 8.08381L7.5057 7.41729C6.81866 6.08614 7.78495 4.5 9.28294 4.5H14.7171C16.2151 4.5 17.1813 6.08614 16.4943 7.41729L16.1503 8.08381C16.0508 8.27665 15.9205 8.45201 15.7646 8.603L12 12.25ZM12 12.25L15.7646 15.897C15.9205 16.048 16.0508 16.2233 16.1503 16.4162L16.4943 17.0827C17.1813 18.4139 16.2151 20 14.7171 20H9.28294C7.78495 20 6.81866 18.4139 7.5057 17.0827L7.84971 16.4162C7.94924 16.2233 8.07949 16.048 8.23536 15.897L12 12.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Hourglass',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
