<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 6.00017C11 5.44789 10.5506 4.99373 10.0026 5.06259C8.77376 5.217 7.59276 5.6553 6.55544 6.34842C5.23985 7.22747 4.21447 8.4769 3.60897 9.93871C3.00347 11.4005 2.84504 13.009 3.15372 14.5609C3.4624 16.1127 4.22433 17.5382 5.34315 18.657C6.46197 19.7758 7.88743 20.5378 9.43928 20.8465C10.9911 21.1551 12.5997 20.9967 14.0615 20.3912C15.5233 19.7857 16.7727 18.7603 17.6518 17.4447C18.3449 16.4074 18.7832 15.2264 18.9376 13.9976C19.0064 13.4496 18.5523 13.0002 18 13.0002H12C11.4477 13.0002 11 12.5525 11 12.0002V6.00017Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M14 4.00006C14 3.44778 14.45 2.99274 14.9966 3.07137C15.5731 3.15429 16.1383 3.30903 16.6788 3.53291C17.5281 3.88469 18.2997 4.40031 18.9497 5.05032C19.5998 5.70033 20.1154 6.472 20.4672 7.32128C20.691 7.86178 20.8458 8.42697 20.9287 9.00346C21.0073 9.55011 20.5523 10.0001 20 10.0001L14.1 10.0001C14.0448 10.0001 14 9.95529 14 9.90006V4.00006Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'PieChart3',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
