<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3943 2.99562L6.00304 7.22466C5.49263 7.62504 5.49264 8.39791 6.00304 8.79829L11.3943 13.0273C11.7567 13.3116 12.2663 13.3116 12.6287 13.0273L18.02 8.79829C18.5304 8.39791 18.5304 7.62504 18.02 7.22466L12.6287 2.99562C12.2663 2.71136 11.7567 2.71137 11.3943 2.99562Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5117 10.0412L18.0201 11.2246C18.5305 11.625 18.5304 12.3979 18.0201 12.7982L12.6287 17.0273C12.2663 17.3116 11.7567 17.3116 11.3943 17.0273L6.00304 12.7983C5.49264 12.3979 5.49263 11.625 6.00304 11.2247L7.51172 10.0412" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5117 14.0412L18.0201 15.2246C18.5305 15.625 18.5304 16.3979 18.0201 16.7982L12.6287 21.0273C12.2663 21.3116 11.7567 21.3116 11.3943 21.0273L6.00304 16.7983C5.49264 16.3979 5.49263 15.625 6.00304 15.2247L7.51172 14.0412" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="12" cy="8" r="2" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'Mapping',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
