<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1325 16.8974L15.9038 19.2114C16.2598 20.2795 17.2594 21 18.3854 21C19.6188 21 20.6846 20.1384 20.9431 18.9323L21.1469 17.9811C21.7128 15.3403 21.8999 12.5982 21.7075 9.90434C21.5854 8.19556 20.353 6.74578 18.6582 6.49567C16.974 6.24712 14.737 6 12.5 6C10.263 6 8.026 6.24712 6.34181 6.49567C4.64702 6.74578 3.4146 8.19556 3.29255 9.90434C3.10013 12.5982 3.28721 15.3403 3.8531 17.9811L4.05692 18.9323C4.31536 20.1384 5.3812 21 6.61465 21C7.74056 21 8.74016 20.2795 9.0962 19.2114L9.86754 16.8974C10.2452 15.7643 11.3056 15 12.5 15C13.6944 15 14.7548 15.7643 15.1325 16.8974Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M8.49976 9V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M16.4998 9V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-dasharray="0.5 2.5"/>
<path d="M14.4998 11H18.4998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-dasharray="0.5 2.5"/>
<path d="M6.5 11H10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M12.5 6L12.5 3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Joystick',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
