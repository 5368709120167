<template>
  <NuxtLink
      :to="link"
      class="relative group w-full md:w-fit leading-none text-center rounded-full cursor-pointer flex items-center justify-center gap-2"
      :class="{
            'py-2 px-4 text-sm': applySize && size === 'login',
            'py-2 px-5 text-sm': applySize && size === 'small',
            'py-3.5 px-5 text-base': applySize && size === 'default',
            'bg-black text-white drop-shadow': variant === 'primary',
            'bg-pink text-black drop-shadow': variant === 'primary-colored',
            'bg-white text-black drop-shadow': variant === 'secondary',
            'bg-transparent text-black border border-black': variant === 'tertiary',
        }"
  >
    <span>{{title}}</span>
    <span class="transition-transform duration-700 group-hover:translate-x-1" v-if="arrow === 'right'"><Icon :name="'ArrowRight'"/></span>
    <span v-if="arrow === 'down'"><span><Icon :name="'DownloadDefault'"/></span></span>
  </NuxtLink>
</template>



<script setup lang="ts">
  import Icon from "../components/Icon.vue";

  const props = defineProps({
    link: {
      type: String,
      default: '#',
    },
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'default',
    },
    arrow: {
      type: String,
      default: '',
    },
  });

  const applySize = computed(() => ['primary', 'secondary', 'download', 'tertiary', 'primary-colored'].includes(props.variant));
</script>
