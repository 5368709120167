<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 5H17C18.1046 5 19 5.89543 19 7V18C19 19.1046 18.1046 20 17 20H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M4 12.5002H14M14 12.5002L10 16.5M14 12.5002L10 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Login',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
