<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M18 10L16 12.0741L14 7L10.526 16.5L8.50005 10.2222L6.32597 13.0223" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'GraphUp',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
