<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 8C3 5.79086 4.79086 4 7 4H17C19.2091 4 21 5.79086 21 8V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V8Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M8 3V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M16 3V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M9 13H15M12 10L12 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'CalendarPlus',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
