<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2704 4.54104C14.2704 3.68995 13.5804 3 12.7293 3H11.2707C10.4196 3 9.72962 3.68995 9.72962 4.54104C9.72962 5.19575 9.30676 5.76411 8.73142 6.07658C8.64146 6.12544 8.55274 6.17624 8.46531 6.22895C7.90042 6.56948 7.1925 6.65124 6.61999 6.32367C5.87291 5.89621 4.92091 6.15129 4.48763 6.89501L3.78322 8.10415C3.35165 8.84495 3.60634 9.79549 4.35047 10.2213C4.92052 10.5474 5.20429 11.1992 5.19041 11.8558C5.18939 11.9037 5.18888 11.9518 5.18888 12C5.18888 12.0482 5.18939 12.0963 5.19041 12.1443C5.2043 12.8009 4.92053 13.4526 4.35049 13.7787C3.60637 14.2045 3.35168 15.155 3.78324 15.8958L4.48768 17.105C4.92095 17.8487 5.87295 18.1038 6.62002 17.6763C7.19252 17.3488 7.90044 17.4305 8.46532 17.7711C8.55275 17.8238 8.64147 17.8746 8.73142 17.9234C9.30676 18.2359 9.72962 18.8042 9.72962 19.459C9.72962 20.3101 10.4196 21 11.2707 21H12.7293C13.5804 21 14.2704 20.3101 14.2704 19.459C14.2704 18.8042 14.6932 18.2359 15.2686 17.9234C15.3585 17.8746 15.4472 17.8238 15.5347 17.7711C16.0995 17.4305 16.8075 17.3488 17.38 17.6763C18.127 18.1038 19.079 17.8487 19.5123 17.105L20.2167 15.8958C20.6483 15.1551 20.3936 14.2045 19.6495 13.7788C19.0795 13.4526 18.7957 12.8009 18.8096 12.1443C18.8106 12.0963 18.8111 12.0482 18.8111 12C18.8111 11.9518 18.8106 11.9037 18.8096 11.8558C18.7957 11.1992 19.0795 10.5474 19.6495 10.2213C20.3937 9.79548 20.6483 8.84494 20.2168 8.10414L19.5124 6.89501C19.0791 6.15128 18.1271 5.8962 17.38 6.32366C16.8075 6.65123 16.0996 6.56948 15.5347 6.22894C15.4472 6.17624 15.3585 6.12543 15.2686 6.07658C14.6932 5.76411 14.2704 5.19575 14.2704 4.54104Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Settings',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
