<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C9.51472 3 7.26472 4.00736 5.63604 5.63604C4.00736 7.26472 3 9.51472 3 12C3 14.4853 4.00736 16.7353 5.63604 18.364C7.26472 19.9926 9.51472 21 12 21C14.4853 21 16.7353 19.9926 18.364 18.364C19.9926 16.7353 21 14.4853 21 12C21 9.51472 19.9926 7.26472 18.364 5.63604C16.7353 4.00736 14.4853 3 12 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 8L12 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 12L14 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'ClockCircle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
