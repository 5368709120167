<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99991 5H7.94143C7.08057 5 6.32428 5.55348 6.14709 6.3959C5.85922 7.76445 5.49991 10.103 5.49991 13.25C5.49991 16.7574 5.94624 19.2606 6.24254 20.5359C6.385 21.1491 6.85044 21.618 7.45788 21.7833C8.54489 22.0789 10.3777 22.5 11.9999 22.5C13.6222 22.5 15.4549 22.0789 16.5419 21.7833C17.1494 21.618 17.6148 21.1491 17.7573 20.5359C18.0536 19.2606 18.4999 16.7574 18.4999 13.25C18.4999 10.103 18.1406 7.76445 17.8527 6.3959C17.6755 5.55348 16.9193 5 16.0584 5H14.9999V2.5H8.99991V5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.49991 17.5H14.4999C15.0522 17.5 15.4999 17.9477 15.4999 18.5V18.6716C15.4999 19.202 15.2834 19.7157 14.8053 19.9455C14.2503 20.2123 13.3409 20.5 11.9999 20.5C10.6589 20.5 9.74948 20.2123 9.19449 19.9455C8.71642 19.7157 8.49991 19.202 8.49991 18.6716V18.5C8.49991 17.9477 8.94762 17.5 9.49991 17.5Z" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'BatteryLow',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
