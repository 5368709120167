<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M14.5 11.1339C15.1667 11.5188 15.1667 12.481 14.5 12.8659L11.5 14.598C10.8333 14.9829 10 14.5018 10 13.732L10 10.2678C10 9.49805 10.8333 9.01692 11.5 9.40182L14.5 11.1339Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'PlayRectangle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
