<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3" y="3" width="18" height="18" rx="9" stroke="currentColor" stroke-width="1.5"/>
<path d="M10 10.2416C10 8.74719 11.1208 8 12.2416 8C13.3624 8 14.1096 8.74719 14.1096 9.86798C14.1096 12.435 12.2416 12.1578 12.2416 13.6039" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<circle cx="12.3177" cy="16.1042" r="0.895932" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'QuestionCircle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
