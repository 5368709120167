<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.0988 11.7165L9.16588 20.8729C8.28956 21.5453 7.03407 21.38 6.36165 20.5037L6.13024 20.2021L6.15614 20.0871C6.56785 18.2594 5.22214 16.5057 3.35016 16.4303L3.23237 16.4256L3.00096 16.124C2.32854 15.2477 2.49383 13.9922 3.37014 13.3198L15.303 4.16332C16.1793 3.4909 17.4348 3.65619 18.1073 4.53251L18.3387 4.83409L18.3256 5.48202C18.2972 6.88485 19.2449 8.11999 20.6073 8.45564L21.2365 8.61067L21.468 8.91225C22.1404 9.78856 21.9751 11.0441 21.0988 11.7165Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<line x1="11.8987" y1="7.72072" x2="16.7813" y2="14.0838" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1 4"/>
</svg>
</template>
<script>
export default {
  name: 'Transport',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
