<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 8.5V8.5C17 6.567 15.433 5 13.5 5H10.0711C8.37498 5 7 6.37498 7 8.07111V8.07111C7 9.37616 7.82479 10.5387 9.05657 10.9698L14.9434 13.0302C16.1752 13.4613 17 14.6238 17 15.9289V15.9289C17 17.625 15.625 19 13.9289 19H10.5C8.567 19 7 17.433 7 15.5V15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 21L12 3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Dollar',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
