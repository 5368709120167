<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 10.8074C3 9.90934 3.40231 9.05854 4.09639 8.4887L9.46186 4.08372C10.9372 2.87248 13.0628 2.87248 14.5381 4.08372L19.9036 8.4887C20.5977 9.05854 21 9.90934 21 10.8074V17.9999C21 19.6568 19.6569 20.9999 18 20.9999H6C4.34315 20.9999 3 19.6568 3 17.9999V10.8074Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M15 21V16C15 14.8954 14.1046 14 13 14H11C9.89543 14 9 14.8954 9 16V21" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'Home2',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
