<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 7V18M12 18L6.5 12.5M12 18L17.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M18 19L6 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'DownloadHover',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
