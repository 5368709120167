<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 12V8C21 5.79086 19.2091 4 17 4H7C4.79086 4 3 5.79086 3 8V17C3 19.2091 4.79086 21 7 21H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M8 3V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M17 10H7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M16 3V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'CalendarNotification',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
