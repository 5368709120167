<template>
  <svg :class="classes" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M-2.40413e-07 6.5L11 6.5M11 6.5L5.5 12M11 6.5L5.5 1" stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>
<script>
export default {
  name: 'ArrowRight',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
