<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="6.75" y="6.75" width="10.5" height="14.5" rx="1.25" stroke="currentColor" stroke-width="1.5"/>
<mask id="path-2-inside-1_47_826" fill="white">
<path d="M9 4C9 2.89543 9.89543 2 11 2H13C14.1046 2 15 2.89543 15 4V7H9V4Z"/>
</mask>
<path d="M7.5 4C7.5 2.067 9.067 0.5 11 0.5H13C14.933 0.5 16.5 2.067 16.5 4H13.5C13.5 3.72386 13.2761 3.5 13 3.5H11C10.7239 3.5 10.5 3.72386 10.5 4H7.5ZM15 7H9H15ZM7.5 7V4C7.5 2.067 9.067 0.5 11 0.5V3.5C10.7239 3.5 10.5 3.72386 10.5 4V7H7.5ZM13 0.5C14.933 0.5 16.5 2.067 16.5 4V7H13.5V4C13.5 3.72386 13.2761 3.5 13 3.5V0.5Z" fill="#070A26" mask="url(#path-2-inside-1_47_826)"/>
<circle cx="12" cy="13" r="1" stroke="currentColor" stroke-width="2"/>
</svg>
</template>
<script>
export default {
  name: 'DataLogger',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
