<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.09229 16.1846V12.5472M7.09229 16.1846H6.72856M7.09229 16.1846H11.8209M7.09229 12.5472H2M7.09229 12.5472V9.63735M2 12.5472V16.1846H3.09121M2 12.5472L3.8083 10.1362C4.04381 9.82216 4.41342 9.63735 4.80594 9.63735H7.09229M7.09229 9.63735V7.24705C7.09229 6.55832 7.65062 6 8.33935 6H15.9142C16.265 6 16.5494 6.28438 16.5494 6.63519V6.63519M15.4582 16.1846H16.5494V15.4354" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="4.91001" cy="16.5481" r="1.43241" stroke="currentColor" stroke-width="1.5"/>
<circle cx="13.6394" cy="16.5481" r="1.43241" stroke="currentColor" stroke-width="1.5"/>
<path d="M10.2193 13.1443H22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.165 11.1033L20.697 11.1033" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.165 9.06177L18.6884 9.06177" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Delivery',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
