<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.0001 12.5L7.00006 12.5M7.00006 12.5L12.5001 7M7.00006 12.5L12.5001 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'ArrowLeft',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
