<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M14.5 11.1339C15.1667 11.5188 15.1667 12.481 14.5 12.8659L11.5 14.598C10.8333 14.9829 10 14.5018 10 13.732L10 10.2678C10 9.49805 10.8333 9.01692 11.5 9.40182L14.5 11.1339Z" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'PlayCircle',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
