<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.3348 14.5273L12 4L7.66525 14.5273C6.39478 17.6127 8.66326 21 12 21C15.3367 21 17.6052 17.6127 16.3348 14.5273Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 14.5L10.5 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Humidity',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
