<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3" y="3" width="18" height="18" rx="4" stroke="currentColor" stroke-width="1.5"/>
<circle cx="12" cy="10" r="3" stroke="currentColor" stroke-width="1.5"/>
<path d="M18.5404 20.5C17.5339 17.8686 14.9852 16 12 16C9.0148 16 6.46605 17.8686 5.45963 20.5" stroke="currentColor" stroke-width="1.5"/>
</svg>
</template>
<script>
export default {
  name: 'UserSquare',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
