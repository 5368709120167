<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.8293 8C19.4175 6.83481 18.3062 6 17 6H12C11.3705 6 10.7777 5.70361 10.4 5.2L9.35 3.8C8.97229 3.29639 8.37951 3 7.75 3H5C3.34315 3 2 4.34315 2 6V18C2 19.6569 3.34315 21 5 21H17C18.3611 21 19.5105 20.0936 19.8774 18.8515" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M11.5 13.5L22 13.5M22 13.5L19.5294 16M22 13.5L19.5294 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'MoveData',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
