<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6667 9.33333V4H9.33333V9.33333H4V14.6667H9.33333V20H14.6667V14.6667H20V9.33333H14.6667Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
<script>
export default {
  name: 'Pharmacies',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
