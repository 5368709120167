<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 9L4.86951 8.56525C6.8402 7.5799 9.1598 7.5799 11.1305 8.56525L12 9L12.8695 9.43475C14.8402 10.4201 17.1598 10.4201 19.1305 9.43475L20 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M4 12L4.86951 11.5652C6.8402 10.5799 9.1598 10.5799 11.1305 11.5652L12 12L12.8695 12.4348C14.8402 13.4201 17.1598 13.4201 19.1305 12.4348L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M4 15L4.86951 14.5652C6.8402 13.5799 9.1598 13.5799 11.1305 14.5652L12 15L12.8695 15.4348C14.8402 16.4201 17.1598 16.4201 19.1305 15.4348L20 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Incubator',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
