<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.71429 12C7.71429 14.6036 5.60363 16.7143 3 16.7143L3 21C7.96584 21 11.9923 16.9782 12 12.0141C12.0076 16.9782 16.0341 21 21 21L21 16.7143C18.3963 16.7143 16.2857 14.6036 16.2857 12L7.71429 12Z" fill="#070A26"/>
<path d="M16.7143 12C16.7143 9.39639 14.6036 7.28573 12 7.28573C9.39638 7.28574 7.28573 9.39636 7.28573 12L3.00002 12C3.00002 7.02943 7.02946 3 12 3C16.9706 3.00001 21 7.02946 21 12H16.7143Z" fill="#070A26"/>
</svg>
</template>
<script>
export default {
  name: 'EuprySquid',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
