<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2499 17.5781C13.9403 18.2808 13.9403 19.4201 13.2499 20.1228C12.5596 20.8255 11.4403 20.8255 10.7499 20.1228C10.0596 19.4201 10.0596 18.2808 10.7499 17.5781C11.4403 16.8753 12.5596 16.8753 13.2499 17.5781Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M7 15.3057C8.69132 13.5841 11.0263 12.9169 13.2164 13.3042" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M4.5 11.7609C5.79964 10.4379 7.35263 9.53012 9 9.03736M19.5 11.7609C17.4289 9.65271 14.7145 8.59863 12 8.59863" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M2 8.2163C3.05701 7.14036 4.23972 6.27035 5.5 5.60626M22 8.2163C18.3409 4.49173 13.1757 3.2348 8.5 4.44551" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M3 3L21 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'WifiNoConnection',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
