<template>
<svg :class="classes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5007 5H16.5007C17.6053 5 18.5007 5.89543 18.5007 7V18C18.5007 19.1046 17.6053 20 16.5007 20H10.5007" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M15 12.4998H5M5 12.4998L9 8.5M5 12.4998L9 16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>
<script>
export default {
  name: 'Logout',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>
